import { useState, useEffect } from "react";
import {
  FormContainer,
  FormItem,
  StyledInput,
  PButton,
  StyledTextArea,
  StyledSelect,
} from "./styled";
import { Row, Col } from "antd";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import Typography from "../../../components/Typography";
import states from "../../../data/states.json";

export default function FormConsultationSmall({
  handleSubmit,
  form,
  theme,
  loading,
  failed,
  referralCode,
  showModal,
  setShowModal,
  fieldsToIgnore = [],
}) {
  const [urlParam, setUrlParams] = useState(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          if (fieldsToIgnore.includes(entry[0])) continue;
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
      form.setFieldsValue({
        email: urlParams.get("email"),
      });
    }

    return () => {
      setUrlParams(null);
    };
  }, [fieldsToIgnore, form]);

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <FormContainer
        name="small-form"
        size="small"
        onFinish={handleSubmit}
        form={form}
      >
        {/* <FormItem
          validateTrigger={"onSubmit"}
          name={"first_name"}
          rules={[
            {
              required: true,
              message: "First name required",
            },
          ]}
        >
          <StyledInput placeholder={"First Name"} />
        </FormItem>
        <FormItem
          validateTrigger={"onSubmit"}
          name={"last_name"}
          rules={[
            {
              required: true,
              message: "Last name required",
            },
          ]}
        >
          <StyledInput placeholder={"Last Name"} />
        </FormItem> */}
        <FormItem
          validateTrigger={"onBlur"}
          name={"email"}
          rules={[
            {
              required: true,
              message: "Email required",
            },
            {
              type: "email",
              message: "Not a valid email",
            },
          ]}
        >
          <StyledInput placeholder={"Email address"} />
        </FormItem>
        {/* <FormItem
          name={"phone"}
          rules={[
            {
              required: true,
              message: "Phone required",
            },
            { pattern: /^\d+$/, message: "Enter only digits" },
          ]}
        >
          <StyledInput placeholder={"Phone Number"} />
        </FormItem> */}
        <Row gutter={4}>
          <Col span={24}>
            <FormItem
              autoComplete="off"
              name={"city"}
              rules={[
                {
                  required: false,
                  message: "State required",
                },
              ]}
            >
              <StyledSelect
                placeholder="Location*"
                optionFilterProp="children"
                options={states.map((state) => ({
                  label: state.label,
                  options: state.options.map((city) => ({
                    label: `${city.label}, ${state.abbreviation}`,
                    value: [city.value, state.abbreviation],
                  }))
                }
                ))}
              />
            </FormItem>
          </Col>
        </Row>
        {/* <FormItem
          name={"message"}
          rules={[
            {
              required: false,
              message: "Comments",
            },
          ]}
        >
          <StyledTextArea placeholder={"Comments"} rows={3} />
        </FormItem> */}
        <FormItem initialValue={referralCode} name={"referral_code"}>
          <StyledInput placeholder={"Promotional Code"} $primary={true} />
        </FormItem>
        <div aria-hidden="true" tabIndex={"-1"} className="form-bot1">
          <FormItem
            name="username"
          >
            <StyledInput placeholder="username" />
          </FormItem>
        </div>
        <FormItem>
          <PButton
            data-cy="submit"
            ptype="primary"
            pname="GET STARTED"
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
            role="link"
          />
        </FormItem>
      </FormContainer>
      <Typography
        margin={[0]}
        textAlign="center"
        color="negative"
        fontSize="14px"
      >
        {failed}
      </Typography>
      <Loading loading={loading} />
    </div>
  );
}
