import styled, { keyframes } from "styled-components";
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const StyledLoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 1);
  border-radius: 50%; /* Circular shape */
  border-top: 4px solid #000; /* Black color on the top border */
  width: 75px;
  height: 75px;
  animation: ${spin} 1s linear infinite; /* Rotate animation */
`;
