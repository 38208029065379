import { useState, useEffect } from "react";
import { customGA4Event, eventFormSubmission } from "../../../services/analytics/ga4CustomEvents";
import { Form } from "antd";
import { withTheme } from "styled-components";
import FormConsultationSmall from "./small";
import FormConsultationLarge from "./large";
import ClassicFormConsultation from "../Consultation-Classic";
import { saveToLocalStorage, setGoogleAdwordsInfo } from "../../../utils/helper";
import useFetch from "../../../hooks/useFetch";
import { useCookies } from "react-cookie";
import LoadingSpinner from "../../../components/LoadingSpinner";

const FormConsultation = ({ theme, variant, referralCode, submitButton, dispatchDestination = "contact", formId }) => {
  const [cookie, setCookie] = useCookies(["converted"]);
  const { submit, loading } = useFetch();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const fieldsToIgnore = ["phone", "email", "first_name", "last_name"];
  //const [splitTestResult, setSplitTestResult] = useState(null);
  //const [splitTestResultLoaded, setSplitTestResultLoaded] = useState(false);
//*****   useEffect(() => {
//    const controller = new AbortController();
  //  const signal = controller.signal;

    //async function fetchABTestResults() {
      //try {
        //const response = await fetch("/api/feature-flag/st", {
          //method: "POST",
         // headers: {
          //  "Content-Type": "application/json",
          //},
          //body: JSON.stringify({ user: window.uniqueVisitorId }),
          //signal: signal,
        //});
        //const data = await response.json();
        //setSplitTestResult(data);
        //setSplitTestResultLoaded(true);
      //} catch (error) {
       // setSplitTestResultLoaded(true);
        //console.error(error);
     // }
    //}

    //const userInterval = setInterval(() => {
     // if (window !== undefined && window.uniqueVisitorId !== undefined) {
      //  fetchABTestResults();
       // clearInterval(userInterval);
     // }
    //}, 500);

    //return () => {
     // clearInterval(userInterval);
      //controller.abort();
      //setSplitTestResultLoaded(false);
      //setSplitTestResult(null);
   // }
  //}, []);

  const handleSubmit = (values) => {
    let url = typeof window !== "undefined" ? window.location.href : "null";
    setGoogleAdwordsInfo(values);
    let apiUrl = process.env.NEXT_PUBLIC_BASE_API_URL + process.env.NEXT_PUBLIC_CLASSIC_STEP_1_ENDPOINT
    submit(apiUrl, "POST", values);
    saveToLocalStorage("choices", values);
    setCookie("converted", "step_1", {
      path: "/",
      maxAge: 86400 * 7 // 7 days
    });
    customGA4Event({ eventName: "Step_1" });
    window.location.href = "/consultation";
  };

 
   return (
      <ClassicFormConsultation
        variant={variant}
        dispatchDestination={dispatchDestination}
        submitButton={submitButton}
        formId={formId}
        referralCode={referralCode}
      />
    );
 


};

export default withTheme(FormConsultation);
