import React from 'react';
import { StyledLoadingSpinner } from './styled';

function LoadingSpinner() {
  return (
    <StyledLoadingSpinner />
  );
}

export default LoadingSpinner;
