import { useEffect, useState } from "react";
import {
  FormContainer,
  FormItem,
  StyledInput,
  PButton,
  StyledTextArea,
  StyledSelect,
  EEAT,
  EEATWRAP,
  EEATText,
} from "./styled";
import { Row, Col } from "antd";
import Loading from "../../../components/Loading";
import ModalContactSuccess from "../../Modals/ModalContactSuccess";
import Typography from "../../../components/Typography";
import states from "../../../data/states.json";
import Image from "next/image";

function FormConsultationLarge({
  handleSubmit,
  form,
  theme,
  loading,
  failed,
  referralCode,
  showModal,
  setShowModal,
}) {
  const [urlParams, setUrlParams] = useState(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      if (queryString) {
        let obj = {};
        const entries = urlParams.entries();
        for (const entry of entries) {
          obj[entry[0]] = entry[1];
        }
        setUrlParams(obj);
      }
      form.setFieldsValue({
        email: urlParams.get("email"),
      });
    }

    return () => {
      setUrlParams(null);
    };
  }, [form]); // Added 'form' to the dependency array

  return (
    <div>
      <ModalContactSuccess
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      />
      <FormContainer
        name="large-form"
        size="small"
        onFinish={handleSubmit}
        form={form}
      >
        <Row className="background-red" gutter={16}>
          <Col span={24}>
            { /* <FormItem
              validateTrigger={"onSubmit"}
              name={"first_name"}
              rules={[
                {
                  required: true,
                  message: "First name required",
                },
              ]}
            >
              <StyledInput placeholder={"First Name"} />
            </FormItem>
            <FormItem
              validateTrigger={"onSubmit"}
              name={"last_name"}
              rules={[
                {
                  required: true,
                  message: "Last name required",
                },
              ]}
            >
              <StyledInput placeholder={"Last Name"} />
            </FormItem> */}
            <FormItem
              validateTrigger={"onBlur"}
              name={"email"}
              rules={[
                {
                  required: true,
                  message: "Email required",
                },
                {
                  type: "email",
                  message: "Not a valid email",
                },
              ]}
            >
              <StyledInput placeholder={"Email address"} />
            </FormItem>
          </Col>
          {/* <FormItem
              name={"phone"}
              rules={[
                {
                  required: true,
                  message: "Phone required",
                },
                { pattern: /^\d+$/, message: "Enter only digits" },
              ]}
            >
              <StyledInput placeholder={"Phone Number"} />
            </FormItem> */}
          <Col span={24}>
            <Row>
              <Col span={24}>
                <FormItem
                  name={"city"}
                  rules={[
                    {
                      required: false,
                      message: "State required",
                    },
                  ]}
                >
                  <StyledSelect
                    placeholder="Location*"
                    optionFilterProp="children"
                    options={states.map((state) => ({
                      label: state.label,
                      options: state.options.map((city) => ({
                        label: `${city.label}, ${state.abbreviation}`,
                        value: [city.value, state.abbreviation],
                      }))
                    }
                    ))}
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem initialValue={referralCode} name={"referral_code"}>
              <StyledInput placeholder={"Promotional Code"} $primary={true} />
            </FormItem>
            {/* <FormItem
              name={"message"}
              rules={[
                {
                  required: false,
                  message: "Comments",
                },
              ]}
            >
              <StyledTextArea placeholder={"Comments"} rows={3} />
            </FormItem> */}
          </Col>
        </Row>
        <div aria-hidden="true" tabIndex={"-1"} className="form-bot1">
          <StyledInput placeholder="username" />
        </div>
        <FormItem>
          <PButton
            ptype="primary"
            pname="GET STARTED"
            width="100%"
            bgColor={theme.colors.prune}
            hoverBgColor={theme.colors.hoverColor2}
            height="20px"
            shadow="yes"
            htmlType="submit"
            role="link"
          />
        </FormItem> <EEATWRAP>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-01.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="40+ years experience"
            >
            </Image>
            <EEATText>40+ Years<br />Experience</EEATText>
          </EEAT>
          <EEAT>   
            <Image
              src="/images/eeat/EEAT-02.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="lifetime limited warranty"
            >
            </Image>
            <EEATText>Limited<br /> Lifetime Warranty</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-03.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="A+ BBB Rating"
            >
            </Image>
            <EEATText>A+ BBB<br />Rating</EEATText>
          </EEAT>
          <EEAT>
            <Image
              src="/images/eeat/EEAT-04.svg"
              width="32px"
              height="44px"
              className="iconImage"
              alt="custom fit"
            >
            </Image>
            <EEATText>Custom<br />Fit</EEATText>
          </EEAT>
        </EEATWRAP>
      </FormContainer>
      <Loading loading={loading} />
      <Typography>{failed}</Typography>
    </div>
  );
}

export default FormConsultationLarge;
