import { useState } from 'react';

const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [failed, setFailed] = useState(false);

  const submit = async (url, method, data) => {
    setLoading(true);

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const json = await response.json();

      setLoading(false);
      setResponseData(json);
    } catch (error) {
      setError(error);
      setLoading(false);
      setFailed(true);
    }
  };

  const submitMultiple = async (requests) => {
    setLoading(true);

    try {
      const promises = requests.map(({ url, method, data }) =>
        fetch(url, {
          method,
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then((response) => response.json())
      );

      const responses = await Promise.all(promises);

      setLoading(false);
      setResponseData(responses);
    } catch (error) {
      setError(error);
      setLoading(false);
      setFailed(true);
    }
  };

  return { loading, error, responseData, submit, submitMultiple, failed };
};

export default useFetch;
